import React, { useCallback, useEffect, useMemo, useState, useContext, createContext } from 'react'
import { Auth } from '@aws-amplify/auth'

const AppContext = createContext()

const AppProvider = props => {
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    onLoad()
  }, [])

  const extractUserFromToken = async () => {
    const currentAuthSession = await Auth.currentSession()
    const idTokenPayload = currentAuthSession.getIdToken().payload

    const user = {
      email: idTokenPayload.email,
    }

    return user
  }

  const prepareUser = async () => {
    const user = await extractUserFromToken()
    setCurrentUser(user)
  }

  const onLoad = async () => {
    try {
      await Auth.currentSession()
      await prepareUser()
    } catch (e) {
      if (e !== 'No current user') {
        console.error(e)
      }
    }
  }

  const isAuthenticated = useCallback(() => {
    return !!currentUser
  }, [currentUser])

  const signIn = useCallback(async (email, password) => {
    await Auth.signIn(email, password)
    await prepareUser()
  }, [])

  const signUp = useCallback(async (email, password) => {
    await Auth.signUp({
      username: email,
      password,
    })
  }, [])

  const signOut = useCallback(async () => {
    await Auth.signOut()
    setCurrentUser(null)
  }, [])

  const confirmSignUp = useCallback(async (email, confirmationCode) => {
    await Auth.confirmSignUp(email, confirmationCode)
  }, [])

  const resendSignUp = useCallback(async email => {
    await Auth.resendSignUp(email)
  }, [])

  const forgotPassword = useCallback(async email => {
    await Auth.forgotPassword(email.toLowerCase())
  }, [])

  const forgotPasswordSubmit = useCallback(async (email, verificationCode, newPassword) => {
    await Auth.forgotPasswordSubmit(email.toLowerCase(), verificationCode, newPassword)
  }, [])

  const changePassword = useCallback(async (oldPassword, newPassword) => {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, oldPassword, newPassword)
  }, [])

  const value = useMemo(
    () => ({
      isAuthenticated,
      signIn,
      signOut,
      signUp,
      confirmSignUp,
      resendSignUp,
      forgotPassword,
      forgotPasswordSubmit,
      changePassword,
    }),
    [
      isAuthenticated,
      signIn,
      signOut,
      signUp,
      confirmSignUp,
      resendSignUp,
      forgotPassword,
      forgotPasswordSubmit,
      changePassword,
    ]
  )

  return <AppContext.Provider value={value} {...props} />
}

const useApp = () => {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('useApp must be used within AppProvider')
  }
  return context
}

export { AppProvider, useApp }
