const React = require('react')
const LogRocket = require('logrocket')
const { Amplify } = require('@aws-amplify/core')
const awsConfig = require('./src/awsConfig')
const { AppProvider } = require('./src/context/appContext')

exports.onClientEntry = () => {
  if (process.env.NODE_ENV === 'production') {
    LogRocket.init('csjf5x/tochka-rechi')
  }

  Amplify.configure(awsConfig.default)
}

exports.wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>
}
